import React from "react";
import "./App.css";
import logo from "./assets/images/logo.jpg";
const App = () => {
  return (
    <div className="mainContainer">
      <div className="container">
        <div className="image-container">
          <img className="img" src={logo} alt="logo" />
          <p className="heading">We will be launching our website soon.</p>
        </div>
      </div>
    </div>
  );
};

export default App;
